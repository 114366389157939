<template>
  <div>

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item>{{ type | getType }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="list">
      <div class="card" v-for="(item, i) in list" :key="'card' + i" @click="onClick(item)">
        <div class="courseWrap">
          <div class="courseImgWrap">
            <el-image class="image" :src="item.img" fit="cover"></el-image>
          </div>
          <div class="courseConWrap">
            <h2 class="courseName mb20">{{ item.label }}</h2>
            <div class="courseInfo">
              <span>会议时间：{{ item.time }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { getnews } from '@/api'

export default {
  name: "HY_list",
  data() {
    return {
      type: sessionStorage.HY_TYPE,
      list: [],
    }
  },
  filters: {
    getType(type) {
      let typetext = "国家级放射影像专业质控中心工作会议"
      if (type) {
        switch (type) {
          case "1":
            typetext = "国家级放射影像专业质控中心工作会议"
            break;
          case "2":
            typetext = "国家级放射影像专业质控中心亚专业组工作会议"
            break;
          case "4":
            typetext = "省级放射影像专业质控中心工作会议"
            break;
          case "3":
            typetext = "地市级放射影像专业质控中心工作会议"
            break;
          default:
            break;
        }
      }
      return typetext
    }
  },
  methods: {
    async getnewsAsync() {
      const r = await getnews({
        type: this.type,
      })
      if (r.code == 1) {
        this.list = await this.handleData(r.data)
        // console.log(this.list);
      } else {
        const msg = r.error.Message;
        this.$message.error(msg)
      }
    },
    async handleData(array) {
      const promises = array.map(async (item) => {
        const img_url = await this.checkFileExists(item.keyWord); // 检查 PNG 是否存在

        return {
          Id: item.Id,
          label: item.Name,
          link: `/${item.keyWord}`,
          img: img_url,
          time: item.sDate,
        };
      });

      return Promise.all(promises); // 等待所有 Promise 完成
    },
    async checkFileExists(keyWord) {
      // 使用示例
      const image = await this.loadImage(keyWord); // 等待加载图片
      if (image) {
        // console.log('加载的图片:', image);
        return image; // 返回加载成功的图片 URL
      } else {
        console.log('没有找到图片');
        return ""; // 或者返回一个默认图片的路径
      }
    },
    async loadImage(keyWord) {
      try {
        // 尝试加载 PNG 图片
        const pngUrl = await import(`@/assets/${keyWord}/1.png`);
        return pngUrl.default; // 返回默认导出
      } catch (error) {
        // console.error('加载 PNG 图片失败:', error);
        try {
          // 如果 PNG 加载失败，则尝试加载 JPG 图片
          const jpgUrl = await import(`@/assets/${keyWord}/1.jpg`);
          return jpgUrl.default; // 返回默认导出
        } catch (error) {
          // console.error('加载 JPG 图片失败:', error);
          return null; // 或者返回一个默认图片
        }
      }
    },
    onClick(item) {
      this.$router.push(item.link)
    }
  },
  created() {
    this.getnewsAsync()
  }
}
</script>

<style scoped>
.list {
  margin: 20px;
}

.card {
  border: 1px #e3e4e6 solid;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
}

.card:hover {
  background: linear-gradient(to bottom, #516bcc, #4459a5);
}
.card:hover .courseName {
  color: #fff;
}
.card:hover .courseInfo {
  color: #fff;
}

.courseWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  margin-right: 20px;
}

.courseImgWrap {
  min-height: 130px;
  min-width: 224px;
}

.courseConWrap {
  color: #888;
  margin-left: 20px;
}

.courseName {
  line-height: 28px;
  font-weight: 600;
  font-size: 18px;
  color: #333;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.mb20 {
  margin-bottom: 20px;
}

.courseInfo {
  line-height: 20px;
  font-size: 14px;
  color: #afafaf;
}

.image {
  width: 224px;
  height: 150px;
  display: block;
}
</style>